<template>
  <ul v-if="gameCount" class="tableM" id="maintableM">
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.name") }}</em>
      <div class="w70w">
        <span class="name pdlr5">
          <span :class="item.partnerLevel" class="box">{{partnerLevelName}}</span><br>
          {{item.memId}}
          <em>{{item.memNick}}</em>
        </span>
      </div>
    </li>
    <li v-if="gameCount['minigame'] || gameCount['mini game']">
      <em class="tableM">{{ $t("front.stributor.miniRate") }}</em>
      <div class="w70w">
        <span class="pdlr5"><em>{{$t('front.stributor.roll')}}</em>: {{ item.miniRate }} %</span>
        <span class="pdlr5"><em>{{$t('front.stributor.lose')}}</em>: {{ item.miniLoseRate }} %</span>
      </div>
    </li>
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.on") }}</em>
      <div class="w70w pdlr5">
        <span class="pdlr5">{{thousand(item.cntBottom)}}</span>
      </div>
    </li>
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.onNow") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.cash.nav3')}}</em><span>{{thousand(inSearchTotal.cashAmt || item.cashAmt)}}</span></li>
          <li><em>{{$t('front.cash.nav4')}}</em><span>{{thousand(inSearchTotal.pointAmt || item.pointAmt)}}</span></li>
        </ul>
      </div>
    </li>
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.dnws") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m7')}}</em><span>{{thousand(inSearchTotal.userCashIn || item.userCashIn)}}</span></li>
          <li><em>{{$t('front.stributor.m8')}}</em><span>{{thousand(inSearchTotal.userCashOut || item.userCashOut)}}</span></li>
          <li><em>{{$t('front.stributor.m9')}}</em><span>{{thousand(inSearchTotal.userCashResultAmt || item.userCashResultAmt)}}</span></li>
        </ul>
      </div>
    </li>
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.giveMoney") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m5')}}</em><span>{{thousand(inSearchTotal.userPayment || item.userPayment)}}</span></li>
          <li><em>{{$t('front.stributor.m6')}}</em><span>{{thousand(inSearchTotal.userPayback || item.userPayback)}}</span></li>
        </ul>
      </div>
    </li>
    <li v-if="gameCount['casino']">
      <em class="tableM tablename">{{ $t("front.gnb.casino") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m10')}}</em><span>{{thousand(inSearchTotal.casinoBetAmt || item.casinoBetAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m11')}}</em><span>{{thousand(inSearchTotal.casinoBetAmtWin || item.casinoBetAmtWin)}}</span></li>
          <li><em>{{$t('front.stributor.m12')}}</em><span>{{thousand(inSearchTotal.casinoRollingAmt || item.casinoRollingAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m13')}}</em>
            <span
              :class="{'rdc' : Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) > 0, 'blc': Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) <= 0}">
              {{ thousand(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) }}
            </span>
          </li>
        </ul>
      </div>
    </li>
    <li v-if="gameCount['slot']">
      <em class="tableM tablename">{{ $t("front.gnb.slot") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m14')}}</em><span>{{thousand(inSearchTotal.slotBetAmt || item.slotBetAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m15')}}</em><span>{{thousand(inSearchTotal.slotBetAmtWin || item.slotBetAmtWin)}}</span></li>
          <li><em>{{$t('front.stributor.m16')}}</em><span>{{thousand(inSearchTotal.slotRollingAmt || item.slotRollingAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m17')}}</em>
            <span
              :class="{'rdc' : Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) > 0, 'blc': Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) <= 0}">
              {{ thousand(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) }}
            </span>
          </li>
        </ul>
      </div>
    </li>
    <li  v-if="gameCount['minigame'] || gameCount['mini game']">
      <em class="tableM tablename">{{ $t("front.gnb.minigame") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m18')}}</em><span>{{thousand(inSearchTotal.miniBetAmt || item.miniBetAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m19')}}</em><span>{{thousand(inSearchTotal.miniBetAmtWin || item.miniBetAmtWin)}}</span></li>
          <li><em>{{$t('front.stributor.m20')}}</em><span>{{thousand(inSearchTotal.miniRollingAmt || item.miniRollingAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m21')}}</em>
            <span
              :class="{'rdc' : Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) > 0, 'blc': Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) <= 0}">
              {{ thousand(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) }}
            </span>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <em class="tableM tablename">{{ $t("front.stributor.allBet") }}</em>
      <div class="w70w">
        <ul class="money">
          <li><em>{{$t('front.stributor.m1')}}</em><span>{{thousand(inSearchTotal.betAmt || item.betAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m2')}}</em><span>{{thousand(inSearchTotal.betAmtWin || item.betAmtWin)}}</span></li>
          <li><em>{{$t('front.stributor.m3')}}</em><span>{{thousand(inSearchTotal.rollingAmt || item.rollingAmt)}}</span></li>
          <li><em>{{$t('front.stributor.m4')}}</em>
            <span
              :class="{'rdc' : Number(inSearchTotal.betResultAmt || item.betResultAmt) > 0, 'blc': Number(inSearchTotal.betResultAmt || item.betResultAmt) <= 0}">
              {{ thousand(inSearchTotal.betResultAmt || item.betResultAmt) }}
            </span>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a class="moreBtnM" @click="getMore" v-if="Number(data.cntBottom) > 0">
        <template v-if="inSearchList.length > 0"><span class="close">파트너 목록 닫기</span></template>
        <template v-else><span class="plus">파트너 목록 열기</span></template>
      </a><!-- ▴▾ -->
    </li>
  </ul>

  <ul v-if="gameCount" class="tablePC" id="tablePC">
    <li class="w10p" :class="item.partnerLevel" style="position: relative;">
      <span class="name">
        <span :class="item.partnerLevel" class="box">{{partnerLevelName}}</span><br>
        {{item.memId}}
        <em>{{item.memNick}}</em>
      </span>
      <a class="moreBtn" @click="getMore" v-if="Number(data.cntBottom) > 0">
        <template v-if="inSearchList.length > 0"><span class="plus">+</span></template>
        <template v-else><span class="x">+</span></template>
      </a><!-- ▴▾ -->
    </li>
    <li class="w7p lRoll" v-if="gameCount['minigame'] || gameCount['mini game']">
      <span><em>{{$t('front.stributor.roll')}}</em>: {{ item.miniRate }} %</span>
      <span><em>{{$t('front.stributor.lose')}}</em>: {{ item.miniLoseRate }} %</span>
    </li>
    <li class="w5p">{{thousand(item.cntBottom)}}</li>
    <li class="w12p">
      <ul class="money">
        <li><em>{{$t('front.cash.nav3')}}</em><span>{{thousand(inSearchTotal.cashAmt || item.cashAmt)}}</span></li>
        <li><em>{{$t('front.cash.nav4')}}</em><span>{{thousand(inSearchTotal.pointAmt || item.pointAmt)}}</span></li>
      </ul>
    </li>
    <li class="w12p">
      <ul class="money">
        <li><em>{{$t('front.stributor.m7')}}</em><span>{{thousand(inSearchTotal.userCashIn || item.userCashIn)}}</span></li>
        <li><em>{{$t('front.stributor.m8')}}</em><span>{{thousand(inSearchTotal.userCashOut || item.userCashOut)}}</span></li>
        <li><em>{{$t('front.stributor.m9')}}</em><span>{{thousand(inSearchTotal.userCashResultAmt || item.userCashResultAmt)}}</span></li>
      </ul>
    </li>
    <li class="w12p">
      <ul class="money">
        <li><em>{{$t('front.stributor.m5')}}</em><span>{{thousand(inSearchTotal.userPayment || item.userPayment)}}</span></li>
        <li><em>{{$t('front.stributor.m6')}}</em><span>{{thousand(inSearchTotal.userPayback || item.userPayback)}}</span></li>
      </ul>
    </li>
    <li class="w12p" v-if="gameCount['casino']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m10')}}</em><span>{{thousand(inSearchTotal.casinoBetAmt || item.casinoBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m11')}}</em><span>{{thousand(inSearchTotal.casinoBetAmtWin || item.casinoBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m12')}}</em><span>{{thousand(inSearchTotal.casinoRollingAmt || item.casinoRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m13')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) > 0, 'blc': Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) }}
          </span>
        </li>
      </ul>
    </li>
    <li class="w12p"  v-if="gameCount['slot']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m14')}}</em><span>{{thousand(inSearchTotal.slotBetAmt || item.slotBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m15')}}</em><span>{{thousand(inSearchTotal.slotBetAmtWin || item.slotBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m16')}}</em><span>{{thousand(inSearchTotal.slotRollingAmt || item.slotRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m17')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) > 0, 'blc': Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) }}
          </span>
        </li>
      </ul>
    </li>
    <li class="w12p"  v-if="gameCount['minigame'] || gameCount['mini game']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m18')}}</em><span>{{thousand(inSearchTotal.miniBetAmt || item.miniBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m19')}}</em><span>{{thousand(inSearchTotal.miniBetAmtWin || item.miniBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m20')}}</em><span>{{thousand(inSearchTotal.miniRollingAmt || item.miniRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m21')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) > 0, 'blc': Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) }}
          </span>
        </li>
      </ul>
    </li>
    <li class="w12p">
      <ul class="money">
        <li><em>{{$t('front.stributor.m1')}}</em><span>{{thousand(inSearchTotal.betAmt || item.betAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m2')}}</em><span>{{thousand(inSearchTotal.betAmtWin || item.betAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m3')}}</em><span>{{thousand(inSearchTotal.rollingAmt || item.rollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m4')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.betResultAmt || item.betResultAmt) > 0, 'blc': Number(inSearchTotal.betResultAmt || item.betResultAmt) <= 0}">
            {{ thousand(inSearchTotal.betResultAmt || item.betResultAmt) }}
          </span>
        </li>
      </ul>
    </li>
  </ul>
  <ul>
    <li v-if="inSearchList && inSearchList.length > 0">
      <div>
        <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                  :table="'more'"
                  :list="inSearchList"
                  :date="date" />
      </div>
    </li>
  </ul>
  <!--tr v-if="gameCount" class="tablePC">
    <td :class="item.partnerLevel">
      <a class="userBetBtn" v-if="Number(data.cntBottom) > 0" @click="showBetList">List</a>
      <span class="name">
        <span :class="item.partnerLevel" class="box">{{partnerLevelName}}</span><br>
        {{item.memId}}
        <em>{{item.memNick}}</em>
      </span>
      <a class="moreBtn" @click="getMore" v-if="Number(data.cntBottom) > 0">
        <template v-if="inSearchList.length > 0"><span class="plus">+</span></template>
        <template v-else><span class="x">+</span></template>
      </a>
    </td>
    <td class="lRoll" v-if="gameCount['casino']">
      <span><em>{{$t('front.stributor.roll')}}</em>: {{ item.casinoRate }} %</span>
      <span><em>{{$t('front.stributor.lose')}}</em>: {{ item.casinoLoseRate }} %</span>
    </td>
    <td class="lRoll"  v-if="gameCount['slot']">
      <span><em>{{$t('front.stributor.roll')}}</em>: {{ item.slotRate }} %</span>
      <span><em>{{$t('front.stributor.lose')}}</em>: {{ item.slotLoseRate }} %</span>
    </td>
    <td class="lRoll"  v-if="gameCount['minigame'] || gameCount['mini game']">
      <span><em>{{$t('front.stributor.roll')}}</em>: {{ item.miniRate }} %</span>
      <span><em>{{$t('front.stributor.lose')}}</em>: {{ item.miniLoseRate }} %</span>
    </td>
    <td>{{thousand(item.cntBottom)}}</td>
    <td>
      <ul class="money">
        <li><em>{{$t('front.cash.nav3')}}</em><span>{{thousand(inSearchTotal.cashAmt || item.cashAmt)}}</span></li>
        <li><em>{{$t('front.cash.nav4')}}</em><span>{{thousand(inSearchTotal.pointAmt || item.pointAmt)}}</span></li>
      </ul>
    </td>
    <td>
      <ul class="money">
        <li><em>{{$t('front.stributor.m7')}}</em><span>{{thousand(inSearchTotal.userCashIn || item.userCashIn)}}</span></li>
        <li><em>{{$t('front.stributor.m8')}}</em><span>{{thousand(inSearchTotal.userCashOut || item.userCashOut)}}</span></li>
        <li><em>{{$t('front.stributor.m9')}}</em><span>{{thousand(inSearchTotal.userCashResultAmt || item.userCashResultAmt)}}</span></li>
      </ul>
    </td>
    <td>
      <ul class="money">
        <li><em>{{$t('front.stributor.m5')}}</em><span>{{thousand(inSearchTotal.userPayment || item.userPayment)}}</span></li>
        <li><em>{{$t('front.stributor.m6')}}</em><span>{{thousand(inSearchTotal.userPayback || item.userPayback)}}</span></li>
      </ul>
    </td>
    <td  v-if="gameCount['casino']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m10')}}</em><span>{{thousand(inSearchTotal.casinoBetAmt || item.casinoBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m11')}}</em><span>{{thousand(inSearchTotal.casinoBetAmtWin || item.casinoBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m12')}}</em><span>{{thousand(inSearchTotal.casinoRollingAmt || item.casinoRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m13')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) > 0, 'blc': Number(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.casinoBetResultAmt || item.casinoBetResultAmt) }}
          </span>
        </li>
      </ul>
    </td>
    <td  v-if="gameCount['slot']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m14')}}</em><span>{{thousand(inSearchTotal.slotBetAmt || item.slotBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m15')}}</em><span>{{thousand(inSearchTotal.slotBetAmtWin || item.slotBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m16')}}</em><span>{{thousand(inSearchTotal.slotRollingAmt || item.slotRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m17')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) > 0, 'blc': Number(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.slotBetResultAmt || item.slotBetResultAmt) }}
          </span>
        </li>
      </ul>
    </td>
    <td  v-if="gameCount['minigame'] || gameCount['mini game']">
      <ul class="money">
        <li><em>{{$t('front.stributor.m18')}}</em><span>{{thousand(inSearchTotal.miniBetAmt || item.miniBetAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m19')}}</em><span>{{thousand(inSearchTotal.miniBetAmtWin || item.miniBetAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m20')}}</em><span>{{thousand(inSearchTotal.miniRollingAmt || item.miniRollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m21')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) > 0, 'blc': Number(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) <= 0}">
            {{ thousand(inSearchTotal.miniBetResultAmt || item.miniBetResultAmt) }}
          </span>
        </li>
      </ul>
    </td>
    <td>
      <ul class="money">
        <li><em>{{$t('front.stributor.m1')}}</em><span>{{thousand(inSearchTotal.betAmt || item.betAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m2')}}</em><span>{{thousand(inSearchTotal.betAmtWin || item.betAmtWin)}}</span></li>
        <li><em>{{$t('front.stributor.m3')}}</em><span>{{thousand(inSearchTotal.rollingAmt || item.rollingAmt)}}</span></li>
        <li><em>{{$t('front.stributor.m4')}}</em>
          <span
              :class="{'rdc' : Number(inSearchTotal.betResultAmt || item.betResultAmt) > 0, 'blc': Number(inSearchTotal.betResultAmt || item.betResultAmt) <= 0}">
            {{ thousand(inSearchTotal.betResultAmt || item.betResultAmt) }}
          </span>
        </li>
      </ul>
    </td>
  </tr>
  <tr v-if="inSearchList && inSearchList.length > 0">
    <td colspan="12">

      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :list="inSearchList"
                 :date="date" />
    </td>
  </tr-->

</template>

<script>
import { retailMyCalculate } from '@/api/retail'
import { mapState } from 'vuex'
import { getDateStr } from '@/libs/utils'
import RetailMainTable from '@/components/member/stributor/RetailMainTable.vue'
import Pagination from '@/components/ui/Pagination'
import { defineAsyncComponent } from 'vue'
import { PARTNER_LEVEL_NAME } from '@/libs/constants'

export default {
  name: 'RetailMainTr',
  components: { RetailMainTable, Pagination },
  props: [
    'data',
    'date',
    'partnerObj'
  ],
  data () {
    return {
      inSearchTotal: {},
      inSearchList: [],
      reqData: {
        searchType: 'INNER',
        chkTodayYn: 'N',
        startDate: getDateStr(new Date(this.date.startDate)),
        endDate: getDateStr(new Date(this.date.endDate)),
        offset: -1,
        searchMemId: this.data.memId
      },
      item: null
    }
  },
  computed: {
    ...mapState([
      'gameCount',
      'commonCode',
      'gameGroupCodes'
    ]),
    partnerLevelName () {
      console.log(this.item.partnerLevel)
      return PARTNER_LEVEL_NAME[this.item.partnerLevel]
    },
    component () {
      return defineAsyncComponent(() => import('@/components/member/stributor/RetailMainTable.vue'))
    }
  },
  created () {
    this.item = { ...this.data }
  },
  methods: {
    showBetList () {
      this.$emit('showBet', this.item.memId)
    },
    async getMore () {
      if (this.inSearchList.length === 0) {
        this.emitter.emit('Loading', true)
        const params = {
          ...this.reqData
        }
        const today = new Date()
        if (params.endDate === getDateStr(today)) {
          params.chkTodayYn = 'Y'
        } else {
          params.chkTodayYn = 'N'
        }
        console.log('[req][retailMyCalculate] : ', params)
        await retailMyCalculate(params).then(res => {
          console.log('[res][retailMyCalculate] : ', res)
          const data = res.data
          if (data.resultCode === '0') {
            this.inSearchList = data.data.inSearchList
            this.inSearchTotal = data.data.inSearchTotal
          }
          this.emitter.emit('Loading', false)
        })
      } else {
        this.inSearchTotal = {}
        this.inSearchList = []
      }
    }
  }
}
</script>

<style scoped>
.PTN_1 { color: #b10000; }
.PTN_2 { color: #d86800; }
.PTN_3 { color: #c4a600; }
.PTN_4 { color: #00a53f; }
.PTN_5 { color: #4668ff; }
.NORMAL { color: #8f5fff; }
.PTN_1.box {background: #b10000;color: #fff; padding: 5px 4px;border-radius: 3px;}
.PTN_2.box {background: #d86800;color: #fff; padding: 5px 4px;border-radius: 3px;}
.PTN_3.box {background: #c4a600;color: #fff; padding: 5px 4px;border-radius: 3px;}
.PTN_4.box {background: #00a53f;color: #fff; padding: 5px 4px;border-radius: 3px;}
.PTN_5.box {background: #4668ff;color: #fff; padding: 5px 4px;border-radius: 3px;}
.NORMAL.box {background: #8f5fff;color: #fff; padding: 5px 4px;border-radius: 3px;}
@media screen and (min-width: 801px) {
  #tablePC {display: flex; justify-content: space-between; align-items: center;}
}
@media screen and (max-width: 800px) {
  .tablePC {display: none;}
  .strTable {width: 100%;}
  #maintableM { display: flex; flex-wrap: wrap; width: calc(100vw - 30px); box-sizing: border-box; margin-bottom: 20px; position: relative; }

  .mobileTable .tablePC { width: 1314px; display: flex; justify-content: space-between; align-items: center;}

  .mobileTable #maintableM { display: none; flex-wrap: wrap; width: calc(100vw - 30px); box-sizing: border-box; margin-bottom: 20px; position: relative; }

  #maintableM > li {width: 100%; display: flex; align-items: center; box-sizing: border-box; font-size: 14px; border-bottom: 1px solid #ccc; border-left: 1px solid #ccc; border-right: 1px solid #ccc; }
  #maintableM > li:first-child {border-top: 1px solid #ccc; width: 60%; border-right: 0; }
  #maintableM > li:nth-child(2) {border-top: 1px solid #ccc; width: 40%;}
  #maintableM > li:first-child em.tablename,  #maintableM > li:nth-child(2) em.tablename {width: 50%;}
  #maintableM > li:first-child .w70w,  #maintableM > li:nth-child(2) .w70w {width: 50%;}
  #maintableM > li:last-child {margin-top: 10px; border: 0;}
  #maintableM > li em.tablename { width: 30%; height: 100%; display: flex; align-items: center; justify-content: center; background: #eeeeee;}
  #maintableM > li > div {padding: 8px 10px;}
  .w70w {width: 70%; text-align: left; box-sizing: border-box;}
  .name{ padding-left: 0; }
  .money li {height: 30px;display: flex;align-items: center; gap: 10px; padding: 0 10px;}
  .money li em {width: 50%;}
  .pdlr5{padding: 0 5px;}
  .moreBtnM {padding: 8px; background: #2F4DF2; color: #fff; border-radius: 5px; display: flex; align-items: center; justify-content: center; box-sizing: border-box; width: 100%;}
}
@media screen and (min-width: 801px) {

  .tableM {display: none;}
}
</style>
